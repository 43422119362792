import { Backdrop, Box, BoxProps, CircularProgress } from '@material-ui/core';
import React from 'react';
import { useStyles } from './style';
const CustomProgress = (props: BoxProps) => {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open>
      <CircularProgress size={50} color="inherit" />
    </Backdrop>
  );
};

export default CustomProgress;
