import React, { createContext, FC, useState } from 'react';
interface userInterface {
  firstName: string;
  lastName: string;
  role: string;
  email: string;
  avatar: string;
}
type AuthType = {
  token: string | null;
  login: (token: string | null) => void;
  user: userInterface;
  logout: () => void;
  setUser: (user: userInterface) => void;
};

export const AuthContext = createContext<AuthType>({
  token: null,
  login: () => {},
  logout: () => {},
  user: { firstName: '', lastName: '', role: '', avatar: '', email: '' },
  setUser: (user) => {},
});

export const AuthProvider: FC = ({ children }) => {
  const tokenStored = localStorage.getItem('token');
  const [token, setToken] = useState<string | null>(JSON.parse(tokenStored as string | ''));
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    role: '',
    avatar: '',
    email: '',
  });
  return (
    <AuthContext.Provider
      value={{
        token,
        user,
        setUser,
        login: (token: string | null) => {
          localStorage.setItem('token', JSON.stringify(token));
          setToken(token);
        },
        logout: async () => {
          localStorage.removeItem('token');
          setToken(null);
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
