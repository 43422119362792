import {
  Button,
  DialogActions,
  DialogContent,
  Drawer,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useMutation } from '@apollo/client';
import { UPDATEBO } from '../gql/mutation';

interface IDrawerModalProps {
  id: string;
  email: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DrawerModal: FC<IDrawerModalProps> = ({ id, email, open, setOpen }) => {
  const classes = useStyles();

  const [currentSuperAdmin, setCurrentSuperAdmin] = useState('');
  const [newCurrentSuperAdmin, setNewCurrentSuperAdmin] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmePassword, setConfirmePassword] = useState('');

  const [errorNewEmail, setErrorNewEmail] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorConfirme, setErrorConfirme] = useState(false);

  const [mutationUpdateBO] = useMutation(UPDATEBO);

  useEffect(() => {
    setCurrentSuperAdmin(email);
  }, [email]);

  const handleClose = () => {
    setErrorNewEmail(false);
    setErrorPassword(false);
    setErrorConfirme(false);
    setOpen(false);
  };

  const handleChange = (texte: string, setText: React.Dispatch<React.SetStateAction<string>>) => {
    setText(texte);
  };

  const validateEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePassword = (password: string) => {
    const re = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
    return re.test(String(password).toLowerCase());
  };

  const handleSave = () => {
    setErrorNewEmail(false);
    setErrorPassword(false);
    setErrorConfirme(false);
    const isValideEmail = newCurrentSuperAdmin !== '' ? validateEmail(newCurrentSuperAdmin) : true;
    const isValidePassword = newPassword !== '' ? validatePassword(newPassword) : true;
    const isComparePass =
      newPassword !== '' ? (newPassword === confirmePassword ? true : false) : true;
    if (
      (newCurrentSuperAdmin === '' || !isValideEmail) &&
      (newPassword === '' || !isValidePassword) &&
      (confirmePassword === '' || isComparePass)
    ) {
      setErrorNewEmail(true);
      setErrorPassword(true);
      setErrorConfirme(true);
      return;
    }

    if (!isValideEmail) {
      setErrorNewEmail(true);
      return;
    }

    if (!isValidePassword) {
      setErrorPassword(true);
      return;
    }

    if (!isComparePass) {
      setErrorConfirme(true);
      return;
    }

    if (isValideEmail || (isValidePassword && isComparePass)) {
      mutationUpdateBO({
        variables: {
          id: id,
          newEmail: newCurrentSuperAdmin,
          newPassword: newPassword,
        },
      })
        .then(() => {
          setOpen(false);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingLeft: 10,
        }}
      >
        <IconButton edge="end" color="default" onClick={handleClose}>
          <ArrowBackIcon />
        </IconButton>
        <Typography
          style={{
            marginLeft: 20,
            color: '#000',
            fontFamily: 'PoppinsBold',
            fontSize: 20,
            letterSpacing: 0,
          }}
          variant="h6"
          component="div"
        >
          MODIFICATION DU PROFIL
        </Typography>
      </div>
      <DialogContent>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="currentMail"
          label="E-mail (actuel)"
          name="currentMail"
          autoFocus
          style={{
            maxWidth: 600,
            height: 'inherit',
            borderRadius: 5,
            marginBottom: 20,
          }}
          className={classes.inputStyle}
          value={currentSuperAdmin}
          disabled
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="newEmail"
          label="Nouvelle Adresse E-mail"
          name="newEmail"
          style={{
            maxWidth: 600,
            height: 'inherit',
            borderRadius: 5,
            marginBottom: 20,
          }}
          className={classes.inputStyle}
          value={newCurrentSuperAdmin}
          onChange={(e) => handleChange(e.target.value, setNewCurrentSuperAdmin)}
          error={errorNewEmail}
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="newPass"
          label="Nouveau Mot de Passe"
          name="newPass"
          type="password"
          style={{
            maxWidth: 600,
            height: 'inherit',
            borderRadius: 5,
            marginBottom: 20,
          }}
          className={classes.inputStyle}
          value={newPassword}
          onChange={(e) => handleChange(e.target.value, setNewPassword)}
          error={errorPassword}
        />

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="currentPass"
          label="Confirmation du Mot de Passe"
          name="currentPass"
          type="password"
          style={{
            maxWidth: 600,
            height: 'inherit',
            borderRadius: 5,
            marginBottom: 20,
          }}
          className={classes.inputStyle}
          value={confirmePassword}
          onChange={(e) => handleChange(e.target.value, setConfirmePassword)}
          error={errorConfirme}
        />
        <DialogActions>
          <Button type="submit" variant="contained" className={classes.submit} onClick={handleSave}>
            Enregistrer
          </Button>
        </DialogActions>
      </DialogContent>
    </Drawer>
  );
};

const drawerWidth = 640;

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    color: 'inherit',
  },

  inputStyle: {
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#3C7548',
    },
    '& .MuiOutlinedInput-input': {
      color: '#5C6360',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
      color: '#3C7548',
    },
    '& .MuiInputLabel-outlined.Mui-focused': {
      color: '#3C7548',
    },
  },
  container: {
    maxHeight: 600,
  },
  tableStyle: {
    marginTop: 30,
  },
  iconButton: {
    padding: 10,
  },

  submit: {
    maxWidth: 300,
    height: 61,
    background: '#3C7548',
    fontFamily: 'PoppinsRegular',
    color: '#FFF',
    fontSize: 20,
    letterSpacing: -0.48,
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
}));
