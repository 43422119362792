import { gql } from '@apollo/client';

export const GETME = gql`
  query getMeBO {
    meBO {
      id
      email
    }
  }
`;

export const GETALLUSERS = gql`
  query GET_ALL_USER(
    $offset: Int!
    $limite: Int!
    $keySearch: String
    $keyFilterCompany: String
    $keyFilterActive: Boolean
  ) {
    getAllUser(
      offset: $offset
      limite: $limite
      keySearch: $keySearch
      keyFilterCompany: $keyFilterCompany
      keyFilterActive: $keyFilterActive
    ) {
      users {
        id
        email
        firstName
        lastName
        company
        isActive
      }
      length
    }
  }
`;

export const GETALLCOMPANY = gql`
  query GET_ALL_COMPANY {
    getAllCompany {
      company
    }
  }
`;
