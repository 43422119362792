import { gql } from '@apollo/client';

export const LOGINBO = gql`
  mutation LoginBO($email: String!, $password: String!) {
    loginBO(email: $email, password: $password) {
      token
      userBO {
        id
        email
        firstName
        lastName
        avatar
      }
    }
  }
`;

export const UPDATEBO = gql`
  mutation Update_BO($id: String!, $newEmail: String, $newPassword: String) {
    updateUserBO(id: $id, newEmail: $newEmail, newPassword: $newPassword)
  }
`;

export const SIGNUPFROMBO = gql`
  mutation SINGUP_FROM_BO($input: UserFromBOInput!) {
    singupFromBO(userInputBO: $input)
  }
`;

export const UPDATEISACTIVE = gql`
  mutation UPDATE_IS_ACTIVE($userId: String!, $isActive: Boolean!) {
    updateStatus(userId: $userId, isActive: $isActive)
  }
`;

export const DELETEUSERINBO = gql`
  mutation DELETE_USER_IN_BO($userId: String!) {
    deleteUserInBO(userId: $userId)
  }
`;
