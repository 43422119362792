import './App.css';
import { AuthProvider } from './providers/AuthProvider';
import { TokenProvider } from './TokenProvider/TokenProvider';

function App() {
  return (
    <AuthProvider>
      <TokenProvider />
    </AuthProvider>
  );
}

export default App;
