import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '../src/fonts/Poppins-Bold.ttf';
import '../src/fonts/Poppins-Light.ttf';
import '../src/fonts/Poppins-Medium.ttf';
import '../src/fonts/Poppins-Regular.ttf';
import '../src/fonts/Poppins-SemiBold.ttf';
import '../src/fonts/Poppins-Thin.ttf';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
