import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  iconButton: {
    marginRight: theme.spacing(-1.25),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxHeight: '100vh',
    justifyContent: 'center',
    height: '100%',
    '& img': {
      marginBottom: 56,
    },
  },
  form: {
    width: '40%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: 'contents',
  },
  submit: {
    maxWidth: 471,
    height: 61,
    background: '#3C7548',
    fontFamily: 'PoppinsRegular',
    color: '#FFF',
    fontSize: 20,
    letterSpacing: -0.48,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
    color: 'white',
  },
  inputStyle: {
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#3C7548',
    },
    '& .MuiOutlinedInput-input': {
      color: '#5C6360',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
      color: '#5C6360',
    },
    '& .MuiInputLabel-outlined.Mui-focused': {
      color: '#5C6360',
    },
    background: '#DBDBDB45',
  },
  loginLeft: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'center',
    overflow: 'hidden',
    height: '100vh',
    background: '#3c754821',
    '& img': {
      maxWidth: 600,
      width: '100%',
      '@media (max-height: 642px)': {
        maxHeight: 500,
      },
    },
  },
  textError: {
    color: '#f70000',
    marginBottom: 20,
  },
}));
