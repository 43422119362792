import { useMutation } from '@apollo/client';
import { CircularProgress, InputAdornment, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LOGINBO } from '../gql/mutation';
import PresentationImage from '../images/Image_Login-BO.svg';
import YBIcon from '../images/logoGreen.svg';
import { AuthContext } from '../providers/AuthProvider';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useStyles } from './constant';

export const Login = () => {
  const classes = useStyles();
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [isVisiblePassword, setIsVisiblePassword] = useState<boolean>(false);
  const [isEmptyEmail, setIsEmptyEmail] = useState<boolean>(false);
  const [isEmptyPassword, setIsEmptyPassword] = useState<boolean>(false);

  const { setUser, login } = useContext(AuthContext);

  const [mutationLogin, { loading }] = useMutation(LOGINBO);

  const handleChange = (text: string, setText: React.Dispatch<React.SetStateAction<string>>) => {
    setText(text);
  };

  const handleNext = () => {
    setUsernameError(false);
    setPasswordError(false);
    if (email === '' && password === '') {
      setIsEmptyEmail(true);
      setIsEmptyPassword(true);
      setUsernameError(true);
      setPasswordError(true);
    } else if (email === '') {
      setIsEmptyEmail(true);
      setUsernameError(true);
    } else if (password === '') {
      setIsEmptyPassword(true);
      setPasswordError(true);
    } else {
      mutationLogin({
        variables: {
          email,
          password,
        },
      })
        .then((res) => {
          if (res.data.loginBO.token) {
            const { email, firstName, lastName, avatar, role } = res.data.loginBO.userBO as {
              firstName: string | null;
              lastName: string | null;
              role: string | null;
              email: string | null;
              avatar: string | null;
            };
            console.log('email:', email);

            login(res.data.loginBO.token);
            setUser({
              firstName: firstName || '',
              lastName: lastName || '',
              email: email || '',
              avatar: avatar || '',
              role: '',
            });
            console.log('push it');

            history.push('/lists');
          }
        })
        .catch((err: Error) => {
          if (err.message === 'invalid-email') {
            setUsernameError(true);
            toast.error("L'adresse e-mail fournie n'est associée à aucun compte", {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (err.message === 'Network request failed') {
            toast.error('Veuillez vérifier votre connexion', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (err.message === 'invalid-password') {
            setPasswordError(true);
            toast.error('Le mot de passe est incorrect', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error('Une erreur est survenue', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={4} className={classes.loginLeft}>
        <img src={PresentationImage} alt="YB-logo" />
      </Grid>
      <Grid item xs={12} sm={8} md={8} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <img src={YBIcon} width={124} alt="YB-logo" />
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Adresse e-mail"
              name="email"
              autoFocus
              style={{
                maxWidth: 471,
                height: 'inherit',
                borderRadius: 5,
                marginBottom: isEmptyEmail ? 5 : 20,
              }}
              className={classes.inputStyle}
              value={email}
              onChange={(e) => {
                handleChange(e.target.value, setEmail);
                setUsernameError(false);
                setIsEmptyEmail(false);
              }}
              error={usernameError}
            />
            {isEmptyEmail ? (
              <Typography variant="overline" className={classes.textError}>
                Le champ email est requis
              </Typography>
            ) : undefined}
            <TextField
              variant="outlined"
              required
              fullWidth
              name="password"
              label="Mot de passe"
              type={isVisiblePassword ? 'text' : 'password'}
              id="password"
              style={{
                maxWidth: 471,
                height: 'inherit',
                borderRadius: 5,
                marginBottom: isEmptyPassword ? 5 : 20,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {isVisiblePassword ? (
                      <VisibilityIcon
                        style={{ color: '#5C6360' }}
                        cursor="default"
                        onClick={() => setIsVisiblePassword(false)}
                      />
                    ) : (
                      <VisibilityOffIcon
                        style={{ color: '#5C6360' }}
                        cursor="default"
                        onClick={() => setIsVisiblePassword(true)}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
              className={classes.inputStyle}
              value={password}
              onChange={(e) => {
                handleChange(e.target.value, setPassword);
                setPasswordError(false);
                setIsEmptyPassword(false);
              }}
              error={passwordError}
            />
            {isEmptyPassword ? (
              <Typography variant="overline" className={classes.textError}>
                Le champ mot de passe est requis
              </Typography>
            ) : undefined}
            <Button fullWidth variant="contained" className={classes.submit} onClick={handleNext}>
              {loading ? (
                <CircularProgress size={24} className={classes.buttonProgress} />
              ) : (
                'SE CONNECTER'
              )}
            </Button>
          </form>
        </div>
      </Grid>
      <ToastContainer />
    </Grid>
  );
};
