import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiCircularProgress-colorPrimary': {
      color: 'green',
    },
    color: 'blue',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 10,
    color: '#3C7548',
    position: 'absolute',
  },
}));
