import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormGroup from '@material-ui/core/FormGroup';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete } from '@material-ui/lab';
import generator from 'generate-password';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import XLSX from 'xlsx';
import { DELETEUSERINBO, SIGNUPFROMBO, UPDATEISACTIVE } from '../gql/mutation';

import { GETALLCOMPANY, GETALLUSERS, GETME } from '../gql/query';
import YBIcon from '../images/YB_Icon.svg';
import { AuthContext } from '../providers/AuthProvider';
import { columns, PurpleSwitch, statusLists, useStyles } from './constant';
import CustomProgress from './CustomProgress';
import { DrawerModal } from './DrawerModal';
import './ListsUser.css';
import { IDataType } from './type';

export const ListsUser = () => {
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const classes = useStyles();
  const history = useHistory();
  const accessToken = localStorage.getItem('token');
  if (accessToken === null) {
    history.push('/');
  }
  const { logout } = useContext(AuthContext);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [superAdmin, setSuperAdmin] = useState('JessiPinkman@yesboss.com');
  const [open, setOpen] = React.useState(false);
  const [fileSelected, setFileSelected] = useState<File>();
  const [listCompanyData, setListCompanyData] = useState<{ comapny: string }[]>([]);
  const [keySearch, setKeySearch] = useState('');
  const [keyCompany, setKeyCompany] = useState('');
  const [keyFilterActive, setKeyFilterActive] = useState<boolean | null>();
  const [openDeleteConfirmModal, setOpenDeleteConfirmModal] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>('');

  const { data, refetch } = useQuery(GETME);
  const [mutationUserFromBO, { loading }] = useMutation(SIGNUPFROMBO);
  const [mutationIsActive] = useMutation(UPDATEISACTIVE);
  const [mutationDeleteUserInBO] = useMutation(DELETEUSERINBO);

  const { data: listUsers, refetch: refetchListUsers } = useQuery(GETALLUSERS, {
    variables: {
      offset: page * rowsPerPage,
      limite: rowsPerPage,
      keySearch: keySearch,
      keyFilterCompany: keyCompany,
      keyFilterActive: keyFilterActive,
    },
  });

  const { data: listCompany, refetch: refecthCompany } = useQuery(GETALLCOMPANY);

  useEffect(() => {
    if (data && data.meBO && data.meBO.email) {
      setSuperAdmin(data.meBO.email);
    }
  }, [data]);

  useEffect(() => {
    // refetchListUsers()
    refecthCompany();
  }, [listUsers]);

  useEffect(() => {
    creatListCompany();
  }, [listCompany]);

  useEffect(() => {
    refetch();
  }, [open]);

  useEffect(() => {
    if (fileSelected) {
      fileProccessing(fileSelected);
    }
  }, [fileSelected]);

  const creatListCompany = () => {
    setListCompanyData(
      listCompany &&
        listCompany.getAllCompany &&
        listCompany.getAllCompany.map((item: any) => {
          if (item.company === null) return { company: 'Pas de société' };
          return { company: item.company };
        }),
    );
  };

  const fileProccessing = (file: File) => {
    let newData: IDataType[] = [];
    let fileReader = new FileReader();
    fileReader.readAsBinaryString(file);
    fileReader.onload = (e) => {
      let data = e.target?.result;
      let fileUser = XLSX.read(data, { type: 'binary' });
      fileUser.SheetNames.forEach((item) => {
        const rowObject = XLSX.utils.sheet_to_json(fileUser.Sheets[item]);
        const castedData: IDataType[] = rowObject.map((item: any) => {
          return {
            company: item['Société'],
            email: item['Email'].toLowerCase(),
            firstName: item['Nom'],
            lastName: item['Prénom'],
            password: generator.generate({
              length: 10,
              numbers: true,
              symbols: true,
              lowercase: true,
              uppercase: true,
            }),
          };
        });
        newData = [...newData, ...castedData];
      });
      mutationUserFromBO({
        variables: { input: { input: newData } },
      })
        .then(() => {
          refetchListUsers();
          toast.success('Le fichier a bien été importé', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
        .catch((err) => {
          toast.error("Une erreur s'est produite lors de l'import du fichier !", {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          throw err;
        });
    };
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const toggleChecked = (value: boolean, id: string) => {
    mutationIsActive({
      variables: { userId: id, isActive: !value },
    }).then(() => {
      refetchListUsers();
      if (value) {
        toast.info("L'utilisateur a été désactivé", {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.success("L'utilisateur a été activé ", {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };

  const toggleSwitch = (value: boolean, id: string) => {
    return (
      <FormGroup>
        <PurpleSwitch checked={value} onChange={() => toggleChecked(value, id)} />
      </FormGroup>
    );
  };

  const deleteUserLine = (id: string) => {
    mutationDeleteUserInBO({
      variables: { userId: id },
    })
      .then(() => {
        toast.success("L'utilisateur a bien été supprimé !", {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setSelectedId('');
        setOpenDeleteConfirmModal(false);
        refetchListUsers();
      })
      .catch(() => {
        toast.error("Une erreur s'est produit!", {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handleDelete = (id: string) => {
    return (
      <Button style={{ borderRadius: '50%', width: 50, height: 60 }}>
        <DeleteIcon
          style={{ opacity: 0.4 }}
          onClick={() => {
            setSelectedId(id);
            setOpenDeleteConfirmModal(true);
          }}
        />
      </Button>
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleLogout = () => {
    logout();
    history.push('/');
  };

  const handleFileChange = () => {
    if (inputFileRef && inputFileRef.current && inputFileRef.current.files) {
      setFileSelected(inputFileRef.current.files[0]);
    }
  };

  const onChangeSearchField = (key: string) => {
    setKeySearch(key);
  };

  const onChangeFilterCompany = (company: string) => {
    setKeyCompany(company);
  };

  const onChangeFilterStatus = (value: string) => {
    if (value === 'Actif') {
      setKeyFilterActive(true);
    } else if (value === 'Inactif') {
      setKeyFilterActive(false);
    } else {
      setKeyFilterActive(null);
    }
  };

  const renderDeleteDialogModal = () => {
    return (
      <Dialog
        open={openDeleteConfirmModal}
        onClose={() => setOpenDeleteConfirmModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="dialog-title-center">{'SUPPRESSION'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Etes-vous sûr de vouloir supprimer cet utilisateur ?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ marginBottom: 10 }}>
          <Button
            variant="outlined"
            color="inherit"
            className={classes.canceledButton}
            onClick={() => setOpenDeleteConfirmModal(false)}
          >
            Annuler
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.confirmButton}
            onClick={() => deleteUserLine(selectedId)}
            autoFocus
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <div className={classes.root}>
      {loading && <CustomProgress />}
      <DrawerModal
        id={data && data.meBO && data.meBO.id}
        email={data && data.meBO && data.meBO.email}
        open={open}
        setOpen={setOpen}
      />
      {renderDeleteDialogModal()}

      <AppBar style={{ backgroundColor: '#3C7548' }} position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
            <img src={YBIcon} width={50} className="img-logo" alt="YB-logo" />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            <h2 className="title-logo">YesBoss Admin</h2>
          </Typography>
          <div className={classes.search} onClick={handleClickOpen}>
            <div className={classes.containerUserAvatar}>
              <p className={classes.userStyle}>{superAdmin}</p>
              <h1 className={classes.avatarStyle}>{superAdmin.substring(0, 1).toUpperCase()}</h1>
            </div>
          </div>
          <button className={classes.logoutBtnStyle} onClick={handleLogout}>
            Déconnexion
          </button>
        </Toolbar>
      </AppBar>

      <div className="body-list">
        <div className="filter-list">
          <Box className="flex-search">
            <h2>Liste des utilisateurs</h2>

            <TextField
              id="outlined-basic"
              label="Rechercher"
              variant="outlined"
              style={{
                maxWidth: 626,
                minWidth: 220,
                width: '100%',
                height: 54,
                borderRadius: 5,
                marginRight: 20,
              }}
              className={classes.inputStyle}
              InputProps={{
                startAdornment: <SearchIcon />,
              }}
              onChange={(e) => onChangeSearchField(e.target.value)}
            />
          </Box>
          <Box className="flex-filter">
            <Autocomplete
              id="combo-box-demo"
              options={listCompanyData}
              getOptionLabel={(option: any) => option.company}
              style={{
                width: '100%',
                marginRight: 20,
                maxWidth: 250,
                minWidth: 180,
              }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  label="Société"
                  variant="outlined"
                  style={{
                    maxWidth: 250,
                    minWidth: 180,
                    width: '100%',
                    height: 54,
                    borderRadius: 5,
                  }}
                  className={classes.inputStyle}
                />
              )}
              onInputChange={(event, value) => onChangeFilterCompany(value)}
            />

            <Autocomplete
              id="combo-box-demo"
              options={statusLists}
              getOptionLabel={(option: any) => option.title}
              style={{ width: '100%', marginRight: 20, maxWidth: 250, minWidth: 180 }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  label="Statut"
                  variant="outlined"
                  style={{
                    width: '100%',
                    maxWidth: 250,
                    minWidth: 180,
                    height: 54,
                    borderRadius: 5,
                  }}
                  className={classes.inputStyle}
                />
              )}
              onInputChange={(event, value) => onChangeFilterStatus(value)}
            />

            <TextField
              type="file"
              inputRef={inputFileRef}
              variant="outlined"
              style={{
                display: 'none',
              }}
              className={classes.inputStyle}
              inputProps={{
                accept: '.csv, .xlsx, xls',
              }}
              onChange={handleFileChange}
            />

            <Button
              onClick={() => {
                if (inputFileRef.current) inputFileRef.current.click();
              }}
              className="import-btn"
              style={{
                width: '100%',
                maxWidth: 254,
                height: 54,
                minWidth: 160,
                background: '#3c7548 0% 0% no-repeat padding-box',
                borderRadius: 5,
                color: '#ffffff',
                letterSpacing: 0,
                fontFamily: 'PoppinsNormal',
              }}
            >
              Importer un fichier Excel
            </Button>
          </Box>
        </div>

        <Paper className={classes.tableStyle}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow style={{ background: 'red' }}>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align="left"
                      style={{
                        minWidth: column.minWidth,
                        fontWeight: 'bold',
                        background: '#DBDBDB',
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {listUsers &&
                  listUsers.getAllUser &&
                  listUsers.getAllUser.users &&
                  listUsers.getAllUser.users.map((row: any) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                        {columns.map((column) => {
                          return (
                            <TableCell key={column.id} align="left" style={{ paddingLeft: 15 }}>
                              {column.label === 'Société'
                                ? row.company
                                : column.label === 'Nom'
                                ? row.firstName
                                : column.label === 'Prénom'
                                ? row.lastName
                                : column.label === 'E-mail'
                                ? row.email
                                : column.label === 'Statut'
                                ? toggleSwitch(row.isActive, row.id)
                                : handleDelete(row.id)}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            labelRowsPerPage="Ligne par page"
            labelDisplayedRows={({ from, to, count }) => (
              <span>
                {from} - {to} sur {count}
              </span>
            )}
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={listUsers && listUsers.getAllUser && listUsers.getAllUser.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
      <ToastContainer />
    </div>
  );
};
