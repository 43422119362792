import { createStyles, alpha, Theme, makeStyles, withStyles } from '@material-ui/core/styles';
import { Column } from './type';
import Switch from '@material-ui/core/Switch';

export const drawerWidth = 640;

export const columns: Column[] = [
  { id: 'comapny', label: 'Société', minWidth: 170 },
  { id: 'name', label: 'Nom', minWidth: 100 },
  {
    id: 'lastName',
    label: 'Prénom',
    minWidth: 170,
  },
  {
    id: 'email',
    label: 'E-mail',
    minWidth: 170,
  },
  {
    id: 'status',
    label: 'Statut',
    minWidth: 100,
    format: (value: number) => value.toFixed(2),
  },
  {
    id: 'remove',
    label: '',
    minWidth: 100,
  },
];

export const statusLists = [
  { title: 'Actif', value: true },
  { title: 'Inactif', value: false },
];

export const PurpleSwitch = withStyles({
  switchBase: {
    color: 'white',
    '&$checked': {
      color: '#3C7548',
    },
    '&$checked + $track': {
      backgroundColor: '#3C7548',
    },
  },
  checked: {},
  track: {},
})(Switch);

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginLeft: 30,
    },
    title: {
      flexGrow: 1,
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
      },
      display: 'flex',
      justifyContent: 'space-between',
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
    inputStyle: {
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#3C7548',
      },
      '& .MuiOutlinedInput-input': {
        color: '#5C6360',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
        color: '#3C7548',
      },
      '& .MuiInputLabel-outlined.Mui-focused': {
        color: '#3C7548',
      },
      '& .MuiFormLabel-root': {
        fontWeight: 'bold',
        color: 'black',
      },
    },
    container: {
      maxHeight: 600,
    },
    tableStyle: {
      marginTop: 30,
      '& .MuiTableCell-body': {
        padding: 0,
      },
    },
    iconButton: {
      padding: 10,
    },
    form: {
      width: '40%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
      display: 'contents',
    },
    submit: {
      maxWidth: 300,
      height: 61,
      background: '#3C7548',
      fontFamily: 'PoppinsRegular',
      color: '#FFF',
      fontSize: 20,
      letterSpacing: -0.48,
    },

    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-start',
    },
    containerUserAvatar: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: 10,
      paddingRight: 10,
      height: 60,
      cursor: 'pointer',
    },
    userStyle: {
      fontSize: 20,
      letterSpacing: -0.32,
      fontFamily: 'PoppinsNormal',
      marginRight: 10,
    },
    avatarStyle: {
      color: 'white',
      width: 50,
      height: 50,
      borderRadius: 50,
      background: '#856b6b',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    logoutBtnStyle: {
      marginLeft: 10,
      background: '#FFFFFFF',
      borderRadius: 4,
      color: '#3C7548',
      height: 60,
      fontSize: 20,
      fontFamily: 'PoppinsNormal',
      borderWidth: 0,
    },
    confirmButton: {
      backgroundColor: '#3C7548',
      '&:hover': {
        backgroundColor: '#3C7548',
      },
    },
    canceledButton: {
      borderColor: '#3C7548',
      '&:hover': {
        borderColor: '#3C7548',
      },
    },
  }),
);
