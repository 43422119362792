import { ApolloProvider } from '@apollo/client';
import React, { useContext } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import createClient from '../config/appoloClient';
import { Login } from '../Login/Login';
import { AuthContext } from '../providers/AuthProvider';
import { ListsUser } from '../User/ListsUser';

export const TokenProvider = () => {
  const { token } = useContext(AuthContext);

  const client = createClient(token ? token : '');

  return (
    <ApolloProvider client={client}>
      <Router>
        <Switch>
          <Route path="/lists">
            <ListsUser />
          </Route>
          <Route path="/">
            <Login />
          </Route>
        </Switch>
      </Router>
    </ApolloProvider>
  );
};
